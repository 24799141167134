import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');

import "bootstrap-icons/font/bootstrap-icons.css"

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import { getSsoLogoutUrl } from "@/services/sso.service";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(
    config => {
        if (localStorage.getItem('bearer') != null) {
            config.headers.authorization = `Bearer ${localStorage.getItem('bearer')}`;
        }
        return config;
    }, error => {
        return Promise.reject(error);
    });

axios.interceptors.response.use(response => {
    return response;
}, error => {
    // Go back to login page when not authenticated
    if (error.response.status === 401) {
        localStorage.clear();
        window.location.replace(getSsoLogoutUrl());
    }
    return Promise.reject(error);
});

const app = createApp(App);

// Use Vuex store and router
app.use(store);
app.use(router);

// Mount the app
app.mount('#app');
