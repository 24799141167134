import { getSsoLoginUrl } from "@/services/sso.service";

const canActivate = (to) => {
    if (localStorage.getItem('token') != undefined) {

        if (to?.meta?.skipMerchantCheck) {
            return true;
        }
        else {
            // Checks if client selected it`s respective merchant
            if (localStorage.getItem('merchant') != undefined) {
                return true;
            }
            else {
                // Redirects to merchant selector
                if (window.location.href != window.location.origin + "/init") {
                    window.location.href = "init";
                }
                
                return true;
            }
        }
    }

    // Navigate to login page
    localStorage.clear();
    window.location.replace(getSsoLoginUrl());
    return false;
};

export {
    canActivate
}